import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Школа водіння RoadReady
			</title>
			<meta name={"description"} content={"Твій шлях до впевненого водіння починається тут"} />
			<meta property={"og:title"} content={"Школа водіння RoadReady"} />
			<meta property={"og:description"} content={"Твій шлях до впевненого водіння починається тут"} />
			<meta property={"og:image"} content={"https://glarevista.com/img/1.png"} />
			<link rel={"shortcut icon"} href={"https://glarevista.com/img/1581912.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://glarevista.com/img/1581912.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://glarevista.com/img/1581912.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://glarevista.com/img/1581912.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://glarevista.com/img/1581912.png"} />
			<meta name={"msapplication-TileImage"} content={"https://glarevista.com/img/1581912.png"} />
		</Helmet>
		<Components.Header>
			<Override slot="quarklycommunityKitMenu" />
		</Components.Header>
		<Section padding="112px 0 112px 0" background="--color-primary" md-padding="96px 0 0px 0" sm-padding="72px 0 0px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="normal 900 50px/1.2 --fontFamily-sansVerdana"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Школа водіння RoadReady
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					lg-width="80%"
				>
					В автошколі RoadReady ми розуміємо, що навчання водінню є важливим етапом. Наша мета - забезпечити всебічну підтримку та всебічний досвід навчання, який допоможе вам стати впевненим та безпечним водієм. Незалежно від того, чи ви тільки починаєте, чи хочете вдосконалити свої навички, ми тут, щоб допомогти вам досягти успіху.
				</Text>
				<Box
					display="flex"
					sm-flex-direction="column"
					sm-width="100%"
					sm-text-align="center"
					justify-content="flex-start"
					align-items="center"
				>
					<Link
						href="/contact"
						padding="12px 24px 12px 24px"
						color="--dark"
						background="--color-secondary"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						transition="background-color 0.2s ease-in-out 0s"
						hover-background="--color-orange"
						sm-margin="0px 0px 16px 0px"
						sm-text-align="center"
						sm-width="100%"
						hover-transition="background-color 0.2s ease-in-out 0s"
						target="_blank"
					>
						Зв'яжіться з нами
					</Link>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
			>
				<Image
					src="https://glarevista.com/img/1.jpg"
					width="760px"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
					sm-width="100%"
				/>
			</Box>
		</Section>
		<Section sm-padding="60px 0 60px 0" padding="65px 0 65px 0" quarkly-title="Images-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="25%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
			>
				<Box width="100%">
					<Image src="https://glarevista.com/img/2.jpg" width="100%" />
				</Box>
			</Box>
			<Box
				display="flex"
				width="25%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Box width="100%">
					<Image src="https://glarevista.com/img/3.jpg" display="block" width="100%" />
				</Box>
			</Box>
			<Box
				lg-align-items="center"
				padding="16px 16px 16px 16px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="25%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
			>
				<Box width="100%">
					<Image display="block" top="auto" src="https://glarevista.com/img/4.jpg" width="100%" />
				</Box>
			</Box>
			<Box
				lg-align-items="center"
				padding="16px 16px 16px 16px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="25%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
			>
				<Box width="100%">
					<Image display="block" top="auto" src="https://glarevista.com/img/5.jpg" width="100%" />
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Text as="h2" font="--headline2" margin="0 0 40px 0" text-align="center">
			Чому варто обрати автошколу RoadReady?
			</Text>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="36px 15px"
				sm-grid-template-columns="1fr"
				sm-grid-gap="12px"
			>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Професійні інструктори
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
					Наша команда сертифікованих інструкторів має багатий досвід і пристрасть до навчання. Вони надають персоналізовані інструкції, які допоможуть вам досягти ваших цілей у водінні.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 30px 0px">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Комплексне навчання
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
					Наша навчальна програма охоплює все - від основ до просунутих технік водіння. Ми використовуємо новітні інструменти та технології, щоб забезпечити першокласну освіту, яка підготує вас до реальних ситуацій на дорозі.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Гнучкий графік занять
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
					Ми пропонуємо різноманітні розклади занять, щоб пристосувати їх до вашого зайнятого життя. Незалежно від того, чи потрібні вам заняття на вихідних, вечірні сесії або індивідуальний графік, у нас є варіанти, які підійдуть саме вам.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 0px 0px">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Сучасні навчальні автомобілі
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
					Наш парк навчальних автомобілів обладнаний найсучаснішими засобами безпеки. Ми дбаємо про те, щоб наші автомобілі були в належному стані та відповідали сучасним вимогам, забезпечуючи безпечне та комфортне навчальне середовище.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 0px 0px">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Високий відсоток успішності
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
					Наші студенти постійно досягають високих результатів на іспитах з водіння. Ми прагнемо підтримувати наші високі стандарти та допомагати вам досягти успіху.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 0px 0px">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Доступні ціни
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
					Ми вважаємо, що якісна водійська освіта повинна бути доступною для всіх. Наші конкурентоспроможні ціни та гнучкі плани оплати гарантують, що ви отримаєте найкращу освіту за доступною ціною.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="140px 0" sm-padding="40px 0 40px 0" background="url(https://glarevista.com/img/6.jpg) center/cover">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="800px" padding="50px 50px 80px 50px" background="--color-light" color="--dark">
				<Text as="h2" font="--headline2" margin="0 0 12px 0">
				Розпочніть свою водійську практику
				</Text>
				<Text font="--base">
				Приєднуйтесь до автошколи RoadReady вже сьогодні та зробіть перший крок до того, щоб стати впевненим та безпечним водієм. Наша досвідчена команда прагне надати вам освіту найвищої якості. Не зволікайте - почніть свою подорож за кермом з нами зараз!
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65ccedc78e2e8e0021782120"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});